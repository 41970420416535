export default defineNuxtPlugin((_) => {
  const api = $fetch.create({
    baseURL: useRuntimeConfig().public.apiPublicUrl,
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
